@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif !;
  background-color: #0D1426 !important;
}


html {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
}

.banner-text {
  font-size: 65px;
  font-weight: bold !important;
  background: linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.25 !important;
  /* This property ensures text color is transparent so the gradient shows through */
  animation: effect 2s linear infinite;
  font-family: "Maven Pro", sans-serif;
}

.banner-text-light {
  font-size: 65px;
  font-weight: 500;
  background: linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.25 !important;
  /* This property ensures text color is transparent so the gradient shows through */
  animation: effect 2s linear infinite;
  font-family: "Maven Pro", sans-serif;
}

.heading-font {
  font-family: "Maven Pro", sans-serif !important;
  background: linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-font2 {
  font-family: "Maven Pro", sans-serif !important;

}

.banner-text2 {
  font-size: 65px !important;
  font-weight: bold !important;
  background: linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.25 !important;
  /* This property ensures text color is transparent so the gradient shows through */
  animation: effect 2s linear infinite;
}

.moveable-control {
  width: 20px !important;
  height: 20px !important;
  margin-top: -10px !important;
  margin-left: -10px !important;
}



.banner-text-small {
  font-size: 16px !important;
  font-weight: bold !important;
  background: linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.25 !important;
  /* This property ensures text color is transparent so the gradient shows through */
  animation: effect 2s linear infinite;
}

.banner-text-small2 {
  font-size: 25px !important;
  font-weight: bold !important;
  background: linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.25 !important;
  /* This property ensures text color is transparent so the gradient shows through */
  animation: effect 2s linear infinite;
}

.swal-confirm-btn {
  background: linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1) !important;
  color: #0F172A !important;
  border-radius: 12px !important;
  border: none !important;
}

.swal-cancel-btn {
  background: linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1) !important;
  color: #0F172A !important;
  border-radius: 12px !important;
  border: none !important;
}

.looper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
}

.looper__innerList[data-animate="true"] {
  animation: scroll 10s linear infinite;
}

.looper__listInstance {
  display: flex;
  animation: loop 10s linear infinite;
}

.slider-image {
  width: 100%;
  height: auto;
  margin-right: 10px;
}

/* VerticalSlider.css */
.slider-container {

  height: 1000px;
  overflow: hidden;

  margin: 0 auto;
}


.vertical-marquee {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.vertical-marquee img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes effect {
  0% {
    background-position: 0% 77%;
  }

  100% {
    background-position: 100% 77%;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.drawing-area {
  position: absolute;
  top: 60px;
  left: 122px;
  z-index: 10;
  width: 200px;
  height: 400px;
  border: 1px solid #ccc;
}

.canvas-container {
  width: 200px;
  height: 400px;
  position: relative;
  user-select: none;
}

#tshirt-div {
  width: 452px;
  height: 548px;
  position: relative;
  background-color: #fff;
}

#canvas {
  position: absolute;
  width: 200px;
  height: 400px;
  left: 0px;
  top: 0px;
  user-select: none;
  cursor: default;
}

/* .rCS1w3zcxh .moveable-control {
  padding: 10px !important;
} */
.color-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid grey;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.circle2 {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  border: 1px solid grey;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.circle:hover {
  transform: scale(1.2);
  cursor: pointer;
}

#font-picker {
  width: 100%;
}

.rotate-Buttons {
  top: 30px !important;
  right: 100px !important;
}

@media only screen and (max-width: 1300px) {

  .rotate-Buttons {
    top: 30px !important;
  }

  .color-options-div {
    bottom: 100px !important;
  }
}

.tshirt-img-scale {
  scale: 0.9 !important
}

.tshirt-img-scale2 {
  scale: 0.6 !important
}

@media only screen and (max-width: 1000px) {
  .edges {
    width: 360px !important;
    height: 180px !important;
  }

  .moveable-control {
    width: 30px !important;
    height: 30px !important;
    margin-top: -14px !important;
    margin-left: -14px !important;
  }

  .rotate-Buttons {
    top: 20px !important;
  }

  .tshirt-img {
    width: 380px !important;
    height: 420px !important;
  }

  .tshirt-img-scale {
    scale: 0.8 !important
  }

  .tshirt-img-scale2 {
    scale: 0.6 !important
  }

  /* .edges-parent {
    margin-top: 20% !important;
  } */

  .style-box {
    left: 100px !important;
    top: 80px !important;

  }

  .Parent {
    width: 180px !important;
    height: 300px !important;
  }

  .color-options-div {
    bottom: 250px !important;
  }

}

@media only screen and (max-width: 400px) {
  .edges {
    width: 300px !important;
    height: 160px !important;
  }

  .rotate-Buttons {
    z-index: 99999;
    top: 25px !important;
  }

  .tshirt-img {
    width: 320px !important;
    height: 380px !important;
  }

  .tshirt-img-scale {
    scale: 0.7 !important
  }

  .tshirt-img-scale2 {
    scale: 0.6 !important
  }

  /* .edges-parent {
    margin-top: 20% !important;
  } */

  .style-box {
    left: 80px !important;
    top: 60px !important;

  }

  .Parent {
    width: 160px !important;
    height: 280px !important;
  }

  .color-options-div {
    bottom: 250px !important;
  }

}

.horizontal .child {
  font-size: 30cqw;
}

.full-size-span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflow text */
  text-align: center;
  /* Center text horizontally */
}

/* .my-swiper .swiper-slide.swiper-slide-next {
  background-color: #0BD1D1;
} */

.my-swiper .swiper-slide {

  border-radius: 15px;
}

.gallery-swiper .swiper-slide {
  background-color: #0F172A;
  margin: 5px;
}

.generate-btn {
  border-radius: 25px !important;
  font-size: 25px !important;


}

#image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-part {
  width: 50%;
}

.MuiTabs-indicator {
  display: none;
}

kendo-pdf-document .pdf-data {

  width: 100% !important;
  border: 1px solid black !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .banner-text2 {
    font-size: 35px !important;
  }

  .banner-text-small2 {
    font-size: 16px !important;
  }
}

kendo-pdf-document image {
  width: 100% !important;
}

@media (max-width: 999px) {

  /* Your styles for screens less than 1000px wide */
  .Web-Edit {
    display: 'none';
  }
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.custom-scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.custom-scrollbar ::-webkit-scrollbar-thumb {
  background: #0F172A;
  border-radius: 5px;
}

/* Handle on hover */
.custom-scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #0F172A;
}

.css-1g5fhnk-MuiBottomNavigationAction-label.Mui-selected {
  color: white !important;
}

.delete-btn {
  border-radius: 50%;
  padding: 0.5em;
  width: 20px;
  height: 20px;
  border: 2px solid #C70000;
  color: white;
  background-color: #C70000;
  display: flex;
  justify-content: center;
  align-items: center;

}

.rotate-div {

  transform: rotateY(45deg);
}

.rotate {
  animation: rotateAnimation 1s linear 1;
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(-45deg);
  }

  to {
    transform: rotateY(-225deg);
  }
}

.rotate-div-shirt-div {

  transform: rotateY(45deg);
}

.rotateshirt {
  animation: rotateAnimationShirt 1s linear 1;
}

@keyframes rotateAnimationShirt {
  from {
    transform: rotateY(45deg);
  }

  to {
    transform: rotateY(180deg);
  }
}

.checkout-btn {
  margin-top: 50px;
  font-family: "Plus Jakarta Sans";
  border-radius: 15px !important;

}

.react-tel-input .form-control {
  background: transparent !important;
}

.demo-simple-select {
  border-color: white !important;

}

.css-gjr1j2-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  border-bottom-color: white !important;
}

a {
  text-decoration: none;
}

#protectedImage {
  user-select: none;
  /* Disable text selection */
}

.slick-next {
  display: none !important;
}

.slick-prev {
  display: none !important;
}

.draggable-text-input {

  padding: 0px !important;
  margin: 0px !important;
  box-sizing: border-box;
  position: relative;
  overflow-x: auto;
  /* Allow horizontal scrolling */
  -webkit-overflow-scrolling: touch;
  /* Enable smooth scrolling on touch devices in Safari */
}

.draggable-text-input {
  touch-action: pan-x;
}

@media only screen and (max-width: 500px) {
  .custom-small-text {
    font-size: 18px !important;
  }

}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-toggle:hover {
  background-color: #45a049;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  /* Increased height to allow more space for scrolling */

  border: 1px solid #ccc;
}

.dropdown-content div {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #ddd;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: white;
  color: black;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-toggle:hover {
  background-color: #d0dad0;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid #ccc;
}

.dropdown-search {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  margin-bottom: 8px;
}

.dropdown-list {
  max-height: 200px;
  overflow-y: scroll;
}

.dropdown-list div {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-list div:hover {
  background-color: #ddd;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  cursor: pointer;
  box-sizing: border-box;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid #ccc;
}

.dropdown-list {
  max-height: 200px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
  /* Ensure the positioning context */
}

/* Custom scrollbar styling for WebKit browsers */
.dropdown-list::-webkit-scrollbar {
  width: 12px;
}

.dropdown-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dropdown-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.dropdown-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.dropdown-list div {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-list div:hover {
  background-color: #ddd;
}

.gallery-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-contextmenu-item {
  color: white;
  cursor: pointer;

}

#context-menu-example .menu-item:hover {
  background-color: #2d3748;
  /* Slightly lighter than the background */
}

.MuiTabs-indicator {
  display: none;
}

.generate-btn2 {
  border-radius: 15px !important;
  width: 94% !important;
}


/* App.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 1111;
}

.no-scroll {
  touch-action: pan-x;
  /* Allows only horizontal scrolling */
  overflow: hidden;
  /* Prevents vertical overflow */
}

#input-with-icon-textfield {
  padding: 4px 8px !important;
}

/* .slick-slide{
  margin: 20px !important;
} */
.banner-text3 {}

.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between !important;
}

.cardHover:hover .buttonHover {
  background: #0F172A !important;
  color: #00D8FF;
}

.cardHover:hover .textHover {
  color: #0F172A;

}

#input-with-icon-textfield[placeholder] {
  color: white !important
}

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* Adjust as needed */
}

.otp-container input {
  width: 50px !important;
  /* Width of each input */
  height: 50px;
  /* Height of each input */
  font-size: 24px;
  /* Font size for the input */
  text-align: center;
  /* Center text */
  border: 2px solid #007BFF;
  /* Border color */
  border-radius: 5px;
  /* Rounded corners */
  margin: 0 5px;
  /* Space between inputs */
  outline: none;
  /* Remove outline on focus */
  transition: border-color 0.3s;
  /* Smooth transition for border color */
  background-color: transparent;
  color: white;

}

/*  */

.otp-input:focus {
  border-color: #0056b3;
  /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Add shadow on focus */
}

.separator {
  font-size: 24px;
  /* Font size for separator */
  margin: 0 5px;
  /* Space around separator */
  color: #007BFF;
  /* Color of separator */
}

.circle-picker {
  width: 100% !important;
}

/* Target the element with the class 'custom-scrollbar' */
.custom-scrollbar {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 transparent;
  /* For Firefox */
}

/* WebKit browsers (like Chrome, Edge, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
  height: 12px;
  /* Height of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
  /* Color of the scrollbar handle */
  border-radius: 6px;
  /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: transparent;
  /* Color when hovered */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  /* Background of the scrollbar track */
  border-radius: 6px;
  /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
  /* Corner styling for both directions */
}

@media (max-width: 600px) {
  .example::-webkit-scrollbar {
    display: none;
  }

  .example {
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    overflow: auto;
  }
}

.swal2-container {
  z-index: 200000;
}

.swal2-confirm {
  border-radius: 12px !important;
  background: linear-gradient(to right, rgb(14, 165, 234), rgb(12, 199, 215), rgb(11, 209, 209)) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  padding: 10px 35px !important;
}

.swal2-cancel {
  border-radius: 12px !important;
  background: #6e7881 !important;
  color: white !important;
  padding: 10px 35px !important;
}

.swal2-popup {
  background-color: #0F172A;
  color: white;
  border-radius: 12px;
}

.signupbtn {
  background: linear-gradient(to right, #0EA5EA, #0CC7D7, #0BD1D1) !important;
}

.circle-picker>span>div>span>div {
  outline: 3px solid gray !important;
}

.image-selection {
  -webkit-touch-callout: none;
  /* Disable long press context menu */
  -webkit-user-select: none;
  /* Disable text selection */
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  /* Prevent interaction */
}

.moveable-control {
  /* Remove all existing styles */
  all: unset;
  position: relative;
  /* Required for pseudo-elements */
  width: 50px;
  /* Adjust to your SVG size */
  height: 50px;
  /* Adjust to your SVG size */
  display: inline-block;
  border: 1px solid gray !important;
  background-color: white !important;
}

.moveable-resizable::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38"><path d="m31.54 31.54v-10.626l-10.626 10.626z"></path><path d="m6.46 6.46h10.626l-10.626 10.626z"></path><path d="m8.332 17.977h21.79v2.5h-21.79z" transform="matrix(.707 .707 -.707 .707 19.227 -7.964)"></path></svg>') !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;

}

.moveable-rotation-control::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg id="fi_16540868" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m12 22a9.99 9.99 0 0 0 7.4873-16.6152h.82a1 1 0 0 0 0-2h-3.4611a1.0413 1.0413 0 0 0 -1 1v3.4614a1 1 0 0 0 2 0v-1.3093a8.0014 8.0014 0 1 1 -5.8462-2.5369 1 1 0 0 0 0-2 10 10 0 0 0 0 20z"></path></svg>') no-repeat center center !important;
  background-size: contain;
}

/* .moveable-line{
  display: none !important;
} */
.moveable-resizable {
  width: 20px !important;
  height: 20px !important;
  margin-top: -2px !important;
  margin-left: -3px !important;
}

.moveable-rotation-control {
  width: 20px !important;
  height: 20px !important;
  margin-top: -2px !important;
  margin-left: -10.5px !important;
}

.ant-modal-content {
  background-color: none !important;
  padding: 0 !important;
  border-radius: 20px !important;
}

.anticon {
  color: #fff !important;

}

.shape {
  background-color: #0F172A !important;
}
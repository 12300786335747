

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.scrollable_div::-webkit-scrollbar {
  display: none !important;
}

.scrollable_div {
  -ms-overflow-style: none !important ; 
  scrollbar-width: none !important;  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.my-swiper .swiper-slide-next div{
  background-color: #0BD1D1 !important;
  
}
.my-swiper .swiper-slide-next .testimonials {
  color: #0F172A !important; 
}
@media screen and (max-width: 785px) {
  .my-swiper .swiper-slide-active div{
    background-color: #0BD1D1 !important;


    
  }
  .testimonials{
    color: #0F172A !important; 
  }
}

/* .my-swiper .swiper-slide .custom-box {
  background-color: #0F172A !important;
} */
